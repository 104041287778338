import React, {useState} from 'react';

const Letter = () => {
  const [permission, setPermission] = useState(false) 
  const [passcode, setPassCode] = useState("")

  const checkPassCode = () => 
  {
    setPermission(passcode === "sharinghearts")
  }
    
  return (
    <div style={{overflow:"scroll", height:"inherit"}}>
        <div style={{display: permission ? "none" : "block", position: "absolute", top:"40%", left:"40%", height:"20%", width:"20%", backgroundColor:"white", borderRadius: "20px", zIndex:"40"}}>
                <h4>Passcode:</h4>
                <input value={passcode} onChange={(e) => setPassCode(e.target.value)} style={{padding:"1%"}}></input>
                <div style={{marginTop: "2%"}}>
                <button onClick={() => checkPassCode()}><span className="fa fa-arrow-right"></span></button>
                </div>
        </div>
        <div>
            <h1 className="letter-title">Quarter of a Century</h1>
        </div>
    <div className='u-center' style={{width:"100%", height:"100%", display:"flex", alignItems:"center"}}>
    <div className="letter" style={{filter: permission ? "" : "blur(4px)"}}>
      <div className="letter-content">
        <p>
          To my dearest Alexandra, 
        </p>
        <p>
          As I look upon the amazing woman that you are becoming, my mind sometimes wanders back to the person I met so many years ago. 
          You were still very much learning to adult - as was I. And, although I expect you to say that you yet haven't fully figured it out
          as you reach this quarter century milestone, I want to you know that I've seen your growth. Your confidence. Your talent. 
          Your care. They astound me every day. If your potential is like a kindering flame, I've watched wood chipping slowly add to the fire. 
          But through this letter, that I incase through time on this eternal digital record, it is the third point I wish to focus on.
         <br/>
          The capacity to care is sadly sometimes a rare quantity in our era. When I first met you, you were, understandably, in danger of perhaps losing it. 
          This world beats you down. Yet, even in your lowest moments, your affections towards others drew me. You gave me love, 
          even when you had little for youself. I think sometimes you don't realise how amazing that is. Now I could gush about your beauty or tenacity,
          but really what truly sets you aside, is your ability to love.
          <br/>
          And that makes me love you. Throughout all of your growth, all of your change, one constant has always stayed. I love you. 
          That is because you can radiate love. Your body will change with the coming years. Your voice might even one day fall victim to time.
          Yet, one thing you can always keep is your care. I will always care for you in turn.
          <br/>
          The strength you give me with your care is the biggest reason I've been able to throw myself fully into the sea of my dreams. 
          Yet, I know you can be more than simply a lifesaver. I want to help you find your own horizon, have you swim besides me as we support each other.
          I can see everything you are capable of and hope I can help you be the best version of the woman I know you can be. 
          <br/>
          I may have been around for but a small portion of your first 25 years on this planet, but I hope to be around for all of the next 25. 
          Your capacity for improvement in the last 4 years astounds me so that I can't wait to see who you'll be in the next quarter century.
          <br/>
          You are my moonlight. 
          My guide in the dark night sky. 
          I love you. 
          <br/>
          Happy Birthday Alex.
          <br />
          Your Sunshine,
          <br/>
          Nico
        </p>
      </div>
    </div>
    </div>
    </div>
  );
};

export default Letter;