import { Link } from "react-router-dom";

function Landing() {

    return (
        <div>
            <div style={{marginTop:"3%"}}>
                <h1 className="Title">Letters of Love</h1>
            </div>
            <div className="Center-Div" style={{marginTop:"2%"}}>
                    <div className="Rounded-Div" style={{background:"aliceblue", height: "70vh", alignItems:"start"}}>
                        <div>
                            <Link to="/letter">
                                <button className="letter-button">Quarter of a Century</button>
                            </Link>
                        </div>
                    </div>
                </div>
        </div>
    );
  }
  
  export default Landing;
  