import React from 'react';
import './App.css';
import { useRoutes, BrowserRouter as Router, Link} from 'react-router-dom';
import Landing from './landing';
import Letter from './Letter';


const Routes = () => {
  const routes = useRoutes([
  { path: "/", element: <Landing/> },
  { path: "/letter", element:<Letter/>}
  // ...
  ]);
  return routes;
}

function App() {
  return (
      <Router>
      <div className="App">
      <Link to="/" className="button Home-Button"><span className="fa fa-home"></span></Link> 
        <Routes/>
      </div> 
    </Router>
  );
}

export default App;
